import { NEXTJS_REVALIDATE_PAGE_IN_SECONDS } from 'pages/index';
import { getEndpoint } from 'utils/helpers';
import { useThemeContext } from 'context/ThemeContext';
import { useEffect } from 'react';
import { GenericPageLayoutProps } from 'components/general/genericPageLayout/GenericPageLayout';
import { parseGenericPage } from 'utils/parsers';
import style from './pageNotFound.module.scss';
import { CustomMetaDataProps } from 'components/general/customMetaData/CustomMetaData';
import { CareerListType } from 'components/careerList/CareerList';
import { ImageType } from 'pages/project/[id]';
import { useIntroContext } from 'context/IntroContext';

type Props = {
  generic: GenericPageLayoutProps;
  metaData: CustomMetaDataProps;
  hero: ImageType;
  careerList: CareerListType;
};

const PageNotFound = (props: Props) => {
  const { setTheme } = useThemeContext();
  const { setHtml } = useIntroContext();
  useEffect(() => {
    setHtml(props.generic.globalData.intro.html);
  }, [setHtml, props.generic.globalData.intro.html]);

  useEffect(() => {
    setTheme('light');
  }, []);

  // const { setGlobalData } = useGlobalDataContext();
  // setGlobalData(props.globalData);

  return (
    <div className={style.container}>
      <div className={style.copyContainer}>
        <p>Page not found</p>
        <h1 className={style.title}>
          This page is corked.
          <br />
          <em>Open a new one.</em>
        </h1>
      </div>
    </div>
  );
};

export async function getStaticProps(context) {
  const genericProps: GenericPageLayoutProps = await parseGenericPage('/careers-page/', context);

  const positions = await getEndpoint('/positions/', context);

  // Pass data to the page via props

  const props: Props = {
    generic: genericProps,
    metaData: {
      titleDefault: genericProps.originalResponse.title,
      title: genericProps.originalResponse.meta_title,
      description: genericProps.originalResponse.meta_description,
      image: genericProps.originalResponse.meta_image,
    },
    hero: genericProps.originalResponse.hero,
    careerList: {
      list: positions,
    },
  };
  return {
    props,
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: NEXTJS_REVALIDATE_PAGE_IN_SECONDS, // In seconds
  };
}

export default PageNotFound;
